import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '~components/Container';
import RichText from '~components/RichText';
import BottomShapeDivider from '~components/ShapeDividers/BottomShapeDivider';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    backgroundColor: props => props.section_background_color || 'inherit'
  },
  contentArea: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '824px',
    marginTop: 61,
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'unset',
      alignItems: 'center',
      textAlign: 'center',
      marginTop: 25
    }
  },
  title: {
    color: props => props.section_text_color,
    fontSize: '64px',
    fontWeight: '700',
    fontFamily: 'Inter',
    lineHeight: '115%',
    textAlign: 'center',
    marginTop: 0,
    marginBottom: 0,
    textTransform: 'capitalize',
    zIndex: 2,
    [theme.breakpoints.down('xs')]: {
      textTransform: 'none',
      textAlign: 'left',
      alignSelf: 'flex-start',
      fontSize: '24px'
    }
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 2,
    '& > *': {
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '170%',
      maxWidth: '498px',
      color: props => props.section_text_color,
      opacity: 0.6,
      [theme.breakpoints.down('xs')]: {
        fontSize: '12px',
        textAlign: 'left',
        maxWidth: '238px'
      }
    },
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      alignSelf: 'flex-start'
    }
  },
  letterArea: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    margin: '24px 0px 10px',
    borderRadius: '20px',
    backgroundColor: props => props.letter_background_color || 'inherit',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      margin: '0px 0px 10px'
    }
  },
  topRightImage: {
    position: 'absolute',
    top: -200,
    right: -20,
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      height: '113px',
      top: -100,
      right: 0
    }
  },
  letterText: {
    padding: '30px 60px',
    borderRadius: '20px',
    backgroundColor: props => props.letter_background_color || 'inherit',
    zIndex: 2,
    [theme.breakpoints.down('xs')]: {
      padding: '20px 27px'
    },
    '& > *': {
      textAlign: 'center',
      fontSize: '14px',
      lineHeight: '165%',
      color: props => props.letter_text_color
    },
    '& > * > strong': {
      fontSize: '16px'
    }
  }
}));

const LetterHero = ({
  title,
  subtitle_text,
  letter,
  letter_background_color,
  letter_text_color,
  section_background_color,
  section_text_color,
  top_right_image,
  bottom_shape_divider
}) => {
  const classes = useStyles({
    section_background_color,
    letter_background_color,
    letter_text_color,
    section_text_color
  });

  return (
    <div className={classes.root}>
      <Container center>
        <div className={classes.contentArea}>
          <h1 className={classes.title}>{title}</h1>
          <RichText
            html={subtitle_text?.html}
            verticalSpacing={0}
            externalClassName={classes.description}
          />
          <div className={classes.letterArea}>
            {top_right_image?.url && (
              <img
                src={top_right_image?.url}
                alt={top_right_image?.alt || title}
                className={classes.topRightImage}
              />
            )}
            <RichText
              html={letter?.html}
              verticalSpacing={0}
              externalClassName={classes.letterText}
            />
          </div>
        </div>
      </Container>
      {/* Shape Divider */}
      {bottom_shape_divider?.url && (
        <BottomShapeDivider src={bottom_shape_divider.url} bgColor="transparent" flex />
      )}
    </div>
  );
};

LetterHero.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle_text: PropTypes.shape({
    html: PropTypes.string.isRequired
  }).isRequired,
  letter: PropTypes.shape({
    html: PropTypes.string.isRequired
  }).isRequired,
  letter_background_color: PropTypes.string,
  letter_text_color: PropTypes.string,
  section_background_color: PropTypes.string,
  section_text_color: PropTypes.string,
  top_right_image: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string
  }),
  bottom_shape_divider: PropTypes.shape({
    alt: PropTypes.string,
    url: PropTypes.string
  })
};

LetterHero.defaultProps = {
  section_background_color: '',
  section_text_color: '',
  letter_background_color: '#FFFFFF',
  letter_text_color: '#000000',
  top_right_image: null,
  bottom_shape_divider: null
};

export default LetterHero;
